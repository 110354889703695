import React from 'react';

function closeNavbar() {
  var navbarCollapse = document.querySelector('.navbar-collapse');
  if (navbarCollapse.classList.contains('show')) {
    navbarCollapse.classList.remove('show');
  }
}

function NavItem ({itemName, itemUrl}) {
  return <li className="nav-item">
    <a className="nav-link text-dark" href={itemUrl} onClick={closeNavbar}>{itemName}</a>
  </li>
}
export default function Navbar () {
  return (
    <>
      {/* TODO: Make the Navbar always present as the logo and collapsable */}
      <nav className="navbar navbar-expand-sm navbar-toggleable-sm 
        navbar-light bg-white">
        <div className="container-fluid">
          <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target=".navbar-collapse" 
            aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* TODO: make this have rounded corners for web */}
          <div className="navbar-collapse 
            collapse d-sm-inline-flex justify-content-between">
            <ul className="navbar-nav flex-grow-1 p-2">
              <NavItem
                itemName="Home" 
                itemUrl="/" />
              <NavItem
                itemName="About Me" 
                itemUrl="/About" />

            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

