import './Stack.css';
// Frontend
import React from './images/React.svg';
import ViteJs from './images/Vite.js.svg';
import Bootstrap from './images/Bootstrap.svg';
import JavaScript from './images/JavaScript.svg';
import CSS from './images/CSS3.svg';
import HTML from './images/HTML5.svg';

//Backend
import Dotnet from './images/NET core.svg';
import CSharp from './images/CSharp.svg';

// DevOps
import Docker from './images/Docker.svg';
import Azure from './images/Azure.svg';
import GitHub from './images/GitHub.svg';
import Git from './images/Git.svg';
import Apache from './images/Apache.svg';

// Servers
import Windows from './images/Windows 11.svg';
import Debian from './images/Debian.svg';
import Ubuntu from './images/Ubuntu.svg';
import ArchLinux from './images/Arch Linux.svg';
import Linux from './images/Linux.svg';

// Data
import Python from './images/Python.svg';
import Matlab from './images/MATLAB.svg';
import SQLite from './images/SQLite.svg';
import AzureSQL from './images/Azure SQL Database.svg';
import MySQL from './images/MySQL.svg';



function StackSection () {
  return (
    <div id="stack_section" className="row row-custom stack-section justify-content-center">
      <div className="col col-12">
        <div className="row stack-list justify-content-center">
          <div className="col col-12 text-center">
            <h1 className="mt-md-3 title-font">My Stack</h1>
          </div>

          <div className="col col-12 col-md-4 mt-2 mt-md-2">
            <div className="row py-0 py-3 p-md-3 h-100">
              <div className="stack-item p-3">
                <div className="col col-12 title-font text-center">
                  <h3 >Frontend</h3>
                </div>
                {/*
              */}
                <div className="col col-12 stack-icons FrontEnd d-flex justify-content-center">
                  <img className="img-fluid" src={HTML} alt="Stack icon" />
                  <img className="img-fluid" src={CSS} alt="Stack icon" />
                  <img className="img-fluid" src={JavaScript} alt="Stack icon" />
                  <img className="img-fluid" src={Bootstrap} alt="Stack icon" />
                  <img className="img-fluid" src={React} alt="Stack icon" />
                  <img className="img-fluid" src={ViteJs} alt="Stack icon" />
                </div>
                <div className="col col-12 Content-font text-start">
                  <p>The languages and frameworks I use for creating responsive and attractive user experiences.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-4 mt-2 mt-md-2">
            <div className="row py-0 py-3 p-md-3 h-100">
              <div className="stack-item p-3">
                <div className="col col-12 title-font text-center">
                  <h3>Backend</h3>
                </div>
                {/*
              */}

                <div className="col col-12 stack-icons BackEnd d-flex justify-content-center">
                  <img className="img-fluid" src={Dotnet} alt="Stack icon" />
                  <img className="img-fluid" src={CSharp} alt="Stack icon" />
                </div>
                <div className="col col-12 Content-font text-start">
                  <p>ASP.NET Core and C# are the core of my software logic and control.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-4 mt-2 mt-md-2">
            <div className="row py-0 py-3 p-md-3 h-100">
              <div className="stack-item p-3">
                <div className="col col-12 title-font text-center">
                  <h3>DevOps</h3>
                </div>
                <div className="col col-12 stack-icons devops d-flex justify-content-center">
                  <img className="img-fluid" src={Azure} alt="Stack icon" />
                  <img className="img-fluid" src={GitHub} alt="Stack icon" />
                  <img className="img-fluid" src={Docker} alt="Stack icon" />
                  <img className="img-fluid" src={Git} alt="Stack icon" />
                  <img className="img-fluid" src={Apache} alt="Stack icon" />
                </div>
                <div className="col col-12 Content-font text-start">
                  <p>The technologies to control my code version and deployment of my projects.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-6 mt-2 mt-md-2">
            <div className="row py-0 py-3 p-md-3 h-100">
              <div className="stack-item p-3">
                <div className="col col-12 title-font text-center">
                  <h3>Server Admin</h3>
                </div>
                <div className="col col-12 stack-icons servers d-flex justify-content-center">
                  <img className="img-fluid" src={Linux} alt="Stack icon" />
                  <img className="img-fluid" src={ArchLinux} alt="Stack icon" />
                  <img className="img-fluid" src={Debian} alt="Stack icon" />
                  <img className="img-fluid" src={Ubuntu} alt="Stack icon" />
                  <img className="img-fluid" src={Windows} alt="Stack icon" />
                </div>
                <div className="col col-12 Content-font text-start">
                  <p>As a server admin, I self-host my projects and services.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-6 mt-2 mt-md-2">
            <div className="row py-0 py-3 p-md-3 h-100">
              <div className="stack-item p-3">
                <div className="col col-12 title-font text-center">
                  <h3>Data Management</h3>
                </div>
                <div className="col col-12 stack-icons data-anagement d-flex justify-content-center">
                  <img className="img-fluid" src={Python} alt="Stack icon" />
                  <img className="img-fluid" src={Matlab} alt="Stack icon" />
                  <img className="img-fluid" src={SQLite} alt="Stack icon" />
                  <img className="img-fluid" src={MySQL} alt="Stack icon" />
                  <img className="img-fluid" src={AzureSQL} alt="Stack icon" />
                </div>
                <div className="col col-12 Content-font text-start">
                  <p>The technologies I use for creating data pipelines, managing databases, and analyzing data.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StackSection;
