import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import './ItemForm.css';
import { formValidation } from './FormValidation.js';


export default function ItemForm ({ onFormSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    units: '',
    wattage: '',
    intensity: '',
    usage: '',
    days: '',
  });
  const [errors, setErrors] = useState ({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var tempErrors = formValidation(formData, setErrors);
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0){
      onFormSubmit(formData);
    }
  }

  return(
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mt-1">
            <label>Name</label>
            <input type="text" 
              name="name" 
              className="form-control" 
              placeholder="Enter appliance name" 
              value={formData.name}
              onChange={handleChange} />
            {errors.name && <span className="text-danger">{errors.name}</span>}
          </div>

          <div className="col mt-1">
            <label>Units</label>
            <input type="number" 
              name="units" 
              className="form-control" 
              placeholder="Enter number of units" 
              value={formData.units}
              onChange={handleChange} />
            {errors.units && <span className="text-danger">{errors.units}</span>}
          </div>

          <div className="col mt-1">
            <label>Wattage</label>
            <input type="number" 
              name="wattage" 
              className="form-control" 
              placeholder="Enter appliance wattage" 
              value={formData.wattage}
              onChange={handleChange} />        
            {errors.wattage && <span className="text-danger">{errors.wattage}</span>}
          </div>
          <div className="col mt-1">
            <label>Intensity</label>
            <input type="number" 
              name="intensity" 
              className="form-control" 
              placeholder="Enter intensity" 
              value={formData.intensity}
              onChange={handleChange} />        
            {errors.intensity && <span className="text-danger">{errors.intensity}</span>}
          </div>
          <div className="col mt-1">
            <label>Usage</label>
            <input type="number" 
              name="usage" 
              className="form-control" 
              placeholder="Enter amount of hours" 
              value={formData.usage}
              onChange={handleChange} />        
            {errors.usage && <span className="text-danger">{errors.usage}</span>}
          </div>

          <div className="col mt-1">
            <label>Days</label>
            <input type="number" 
              name="days" 
              className="form-control" 
              placeholder="Enter amount of days" 
              value={formData.days}
              onChange={handleChange} />        
            {errors.days && <span className="text-danger">{errors.days}</span>}
          </div>

          <div className="text-center my-2">
            <button type="submit" 
              className="btn btn-primary"
            >
              Add
            </button>
          </div>      
        </div>
      </form>
    </div>
  );
}
