import React from 'react';
import Navbar from './Navbar.js';
import Stack from './Stack.js';
import Footer from './Footer.js';
import './About.css';

import PhotoOfMe from './images/me.jpg';

const AboutMePage = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div id="about-me-section" 
          className="row mt-5 mx-1 pt-5 justify-content-center about-me-section">
          <div className="col col-12 col-md-10">
            <div className="row p-2 p-md-5 pt-3">
              <div className="col order-2">
                <h1 className="text-center subtitle-font">About Alejandro Núñez</h1>
                <img src={PhotoOfMe} alt="me" />
                <p className="text-justify content-font">I'm an Electrical and Data engineer, a .NET and Full Stack Web developer, a DevOps engineer, a Linux System Admin and I use Arch btw. My passions, education, and career have led me to acquire a broad set of skills that I now use to solve business problems. My skill set allows me to design optimal control systems from Environmental Resource Management systems to simple ToDo Web Apps. I worked on electrical power plants' SCADA, designed electrical energy dispatch controls, deployed data pipelines, designed data infrastructures, created cloud-based monitoring systems using the IIoT standards, and developed full-stack web apps. I love building web and software solutions for businesses, especially in the engineering, financial, economy, and resource management sectors.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-1 pt-5 justify-content-center">
          <div className="col col-12 col-md-10 projects text-center">
                <h1 className="subtitle-font">Visit My Web Portfolio</h1>
                <p className="content-font">for more projects from me</p> 
                <a href="https://alejandro-nunez.com" className="btn btn-primary">Portfolio</a>
          </div>
        </div>


        <div className="row mb-5 mx-1 pt-5 justify-content-center stack">
          <div className="col col-12 col-md-10">
            <Stack />
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}
export default AboutMePage;
